import React, { useMemo } from 'react'
import promos from 'config/promos'
import Logo from 'components/Logo'
import {
  Navbar, useTranslation, useLocalizedUrl, useLanguage
} from '@sailogy/shipwright'
import { K } from './i18n'

interface Props {
  overlay?: boolean
  hideAll?: boolean
  overrideLogoURL?: string
  hideProfile?: boolean
}


const WebsiteNavbar: React.FC<Props> = ({
  overlay,
  hideAll = false,
  overrideLogoURL,
  hideProfile = false
}) => {
  const t = useTranslation<K>()
  const localizedUrl = useLocalizedUrl()
  const [language] = useLanguage()
  const menu = useMemo(() => ((hideAll) ? [] : [
    {
      label: t('Our dedicated bases'),
      href: localizedUrl('/bases')
    },
    {
      label: t('Find your boat'),
      href: localizedUrl('/search')
    },
    // TEMP
    promos.black_friday ? {
      label: t('Best offers'),
      href: localizedUrl('/search')
    } : {
      label: t('Best offers'),
      menu: [
        {
          label: t('Special offers'),
          href: localizedUrl('/offers_special')
        },
        {
          label: t('Weekend'),
          href: localizedUrl('/offers_weekend')
        },
        {
          label: t('Last minute offers'),
          href: localizedUrl('/offers_lastminute')
        }
      ]
    },
    {
      label: t('Yacht invest'),
      href: localizedUrl('/p/yachtinvest')
    }
  ]), [language, localizedUrl])

  return (
    <Navbar
      logo={Logo}
      menu={menu}
      overlay={overlay}
      forcePrimaryColorLogo
      overrideLogoURL={overrideLogoURL}
      hideProfile={hideProfile}
    />
  )
}

export default React.memo(WebsiteNavbar)
