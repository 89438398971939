/* eslint max-len: off */
import { useState } from 'react'

const Content: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true)

  return (
    <>
      <p>
        Freuen Sie sich darauf, die Segel zu setzen und das Meer zu genießen?
        Suchen Sie nach einer sicheren und benutzerfreundlichen Website für die
        Bootsvermietung? Weiter als bei Five Seasons Yachting brauchen Sie gar nicht
        zu suchen! Als führende Online-Plattform für Bootsvermietungen verfügt
        Five Seasons Yachting über eine beeindruckende Flotte von über 22.000 Booten,
        darunter Segelboote, Motorboote, Katamarane und traditionelle türkische
        Gulets. Mit einer globalen Präsenz in Häfen auf 5 Kontinenten bietet
        Five Seasons Yachting Urlaubslösungen für alle Jahreszeiten. Wählen Sie aus
        über tausend Reisezielen und starten Sie mit Five Seasons Yachting in Ihr
        traumhaftes Bootsabenteuer!
      </p>
      {collapsed
        && (
          <a
            onClick={() => setCollapsed(false)}
            role="button"
            tabIndex={-1}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            Mehr lesen
          </a>
        )}
      <div style={{ display: collapsed ? 'none' : 'block' }}>
        <h3>Wie mietet man ein Boot mit Five Seasons Yachting?</h3>
        <p>
          Wenn es um Segelurlaube geht, sind Sie bei Five Seasons Yachting an der
          richtigen Adresse - der führenden Plattform für die Vermietung von
          Booten und Yachten, die Sicherheit und Komfort garantiert. Mit nur
          wenigen Klicks können Sie auf der benutzerfreundlichen Website von
          Five Seasons Yachting Ihr perfektes Abenteuer auf See organisieren. Egal, ob
          Sie ein begeisterter Segler oder ein Anfänger sind, wir haben alles,
          was Sie brauchen, um einen außergewöhnlichen Törn auf dem offenen Meer
          zu planen.
        </p>
        <p>
          Unser engagiertes Team von 40 mehrsprachigen Experten (Italienisch,
          Englisch, Deutsch, Französisch, Spanisch, Russisch, Kroatisch,
          Albanisch und Griechisch) steht Ihnen von unseren Büros in Deutschland
          und der Schweiz aus während Ihrer Reise zur Seite.
        </p>
        <p>
          Unsere firmeneigene Software ermöglicht es unseren Kunden, eine
          umfangreiche Auswahl an Booten, Reisezielen und Inspirationen zu
          erkunden, so dass Sie Ihren Traumtörn direkt auf unserer Plattform
          buchen können. Wir bieten sowohl Charter mit Skipper als auch Bareboat
          zu erschwinglichen Preisen an und bieten Ihnen ein Höchstmaß an
          Garantien, damit Sie sich keine Sorgen machen müssen. Und wenn Sie bei
          uns buchen, können Sie die Kosten mit anderen Reisenden teilen, was
          das Segeln noch einfacher zugänglich macht.
        </p>
        <p>
          Wir von Five Seasons Yachting sind stolz darauf, eine unabhängige Agentur zu
          sein,
          die mit keiner Werft oder Charterfirma verbunden ist. Dieser Ansatz
          ermöglicht es uns, eine kundenorientierte Philosophie beizubehalten
          und Ihnen unparteiisch die besten verfügbaren Bootsoptionen zu
          präsentieren.
        </p>
        <p>
          Unsere Flotte ist unvergleichlich groß und zuverlässig ausgesucht. Sie
          besteht aus Booten erstklassiger Charterunternehmen, die regelmäßigen
          Qualitätskontrollen unterzogen werden. Unser engagiertes Team, die
          Sail Hunters, bereist die Welt, baut persönliche Beziehungen zu
          Bootseigentümern auf und nimmt Fotos und Videos der Boote auf, um
          sicherzustellen, dass wir nur geprüfte - und überprüfte Angebote auf
          unserer Website veröffentlichen und Ihren Traum von einem perfekten
          gemieteten Segelboot zu erfüllen.
        </p>
        <p>
          Auf der Five Seasons Yachting-Website finden Sie eine große Auswahl an
          Booten,
          sowohl mit als auch ohne Skipper, die alle von renommierten
          Charterunternehmen mit einer außergewöhnlichen Erfolgsbilanz geführt
          werden. Unser firmeneigener Algorithmus berücksichtigt die Leistung
          der Boote, das Verkaufsvolumen und die Kundenrezensionen, so dass die
          Qualität der Flotte in Echtzeit überwacht werden kann.
        </p>
        <p>
          Wählen Sie Five Seasons Yachting für Ihr nächstes Segelabenteuer und erleben
          Sie das
          Beste, was die Meere dieser Welt zu bieten haben!
        </p>
        <h3>Last Minute Boote mieten</h3>
        <p>
          In der Welt des Bootssports und der Yachtcharter zeichnet sich Master
          Yachting
          als führender Dienstleister aus und bietet seinen Kunden eine Reihe
          wichtiger Dienstleistungen an. Einer dieser wichtigen Dienste ist die
          Möglichkeit, über unsere fortschrittliche Website
          Last-Minute-Bootsreservierungen oder -Bootsanmietungen vorzunehmen.
          Auf dieser Plattform werden nur verfügbare Boote mit regelmäßig
          aktualisierten Angeboten zu den besten Marktpreisen angezeigt. Die
          Kunden können auf die Zuverlässigkeit und Sicherheit dieses Dienstes
          unter Garantie vertrauen. Durch die Verbindung mit Five Seasons Yachting
          können die
          Nutzer wertvolle Zeit im Buchungsprozess sparen, da sie nicht mehr auf
          Angebote warten müssen; alle Angebote sind sofort online verfügbar.
        </p>
        <p>
          Five Seasons Yachting gewährleistet außerdem vollständige Transparenz bei
          den
          Mietpreisen für Yachten. Bei diesem Portal gibt es keine versteckten
          Kosten oder Überraschungen; alle Extras werden auf der Website klar
          angegeben, einschließlich obligatorischer Posten wie
          Endreinigungskosten und optionaler Dienstleistungen.
        </p>
        <p>
          Der Buchungsprozess bei Five Seasons Yachting ist schnell und unkompliziert.
          Die
          Kunden erhalten eine sofortige Bestätigung, so dass sie sofort
          zusätzliche Produkte und Zubehör buchen können. So können sie sich
          beispielsweise Angebote für Flugtickets für die Anreise zum Starthafen
          durch Partner einholen lassen.
        </p>
        <h3>Ihr vertrauenswürdiges Yachtcharter-Unternehmen</h3>
        <p>
          Schließlich bietet Five Seasons Yachting eine außergewöhnliche
          Kundenbetreuung durch
          sein engagiertes und nautisch versiertes Charterexpertenteam.
          Unabhängig davon, ob Reisende zum ersten Mal einen Segelurlaub machen
          oder erfahrene Segler sind, haben sie Zugang zu einem Team von
          erfahrenen Beratern, die auf der ganzen Welt verfügbar sind. Diese
          Berater geben wertvolle Ratschläge für die Auswahl des perfekten
          Bootes, die Planung der Reiseroute und kümmern sich um die Absprachen
          und die Kommunikation mit den Charterunternehmen, sowohl während als
          auch nach dem Urlaub.
        </p>
        <p>
          Was Five Seasons Yachting von seinen Mitbewerbern unterscheidet, ist die
          Anwesenheit
          von spezialisierten Teams, die 7 Tage die Woche während des gesamten
          Urlaubs zur Verfügung stehen. Sie fungieren als Vermittler zwischen
          den Chartergesellschaften und kümmern sich um alle Zweifel und Fragen,
          die auftreten können. Unabhängig davon, wo sich das gemietete Boot
          befindet, steht den Reisenden immer ein mehrsprachiger Ansprechpartner
          zur Verfügung.
          Dank dieser außergewöhnlichen Dienstleistungen ist Five Seasons Yachting zu
          einer
          führenden Agentur geworden, sowohl für professionelle Segler als auch
          für weniger erfahrene Reisende, die das perfekte Boot für ihren Urlaub
          suchen. Five Seasons Yachting eröffnet einem breiten Publikum die Welt des
          Segelns
          und bietet ihnen die Möglichkeit, ein exklusives Erlebnis mit einem
          Budget zu genießen, das mit dem eines traditionellen Urlaubs an Land
          vergleichbar ist.
        </p>
      </div>
    </>
  )
}

export default Content
